<!--  -->
<template>
  <div>
    <pHeader />
    <div
      class="banner"
      :style="{'background-image':'url('+require('@/assets/img/banner_register.png')+')'}"
    >
      <div class="txt-box">
        <h3>助力行业巨头、独角兽企业</h3>
        <p>Castle为各行各业赋能，10W+政企单位的共同选择</p>
      </div>
    </div>
    <div class="content">
      <div class="inner">
        <div class="header-title">
          <div class="tit">项目登记</div>
        </div>
        <div class="project layout-flex">
          <div class="item" v-for="(item,index) in 5" :key="index">
            <div class="pro_tit">潍坊华创信息技术有限公司</div>
            <p class="pro_dec">
              华创科技，专业从事信息化系统建设服务。主要针对企业集成、大数据、人工智
              能、物联网等领域进行研发。拥有一批长期专业从事软件开发、软件定制的专业
              人才，具有雄厚的技术开发实力，全...
            </p>
            <div class="button layout-flex">跳转网站</div>
          </div>
        </div>
        <pagination total="40" currentPage="1" pageSize="10"></pagination>
      </div>
    </div>
    <pFooter />
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.content {
  background: $bg;
  .inner {
    padding: 4rem 0;
    max-width: $minWidth;
    margin: 0 auto;
    .project {
      flex-wrap: wrap;
      .item {
        margin-bottom: 3rem;
        width: 48%;
        height: 25rem;
        padding: 3.5rem 3rem;
        background: #fff;
        border-radius: 0.4rem;
        &:not(:nth-of-type(2n)) {
          margin-right: 2%;
        }
        .pro_tit {
          margin-bottom: 1rem;
          font-size: 2rem;
        }
        .pro_dec {
          @include show_line(3);
          margin-bottom: 1.2rem;
          font-size: 1.5rem;
          color: #999999;
          line-height: 1.7;
        }

        .button {
          justify-content: center;
          width: 10rem;
          height: 3.4rem;
          line-height: 3.4rem;
          font-size: 1.5rem;
          color: #376fff;
          border-radius: 1.5rem;
          border: 1px solid #376fff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>